<template>
  <b-card>
    <b-card-title>Company Summary</b-card-title>
    <!-- <b-card-body> -->
    <p class="px-1">
      {{ companyData.company_summary ? companyData.company_summary : 'No summary' }}
    </p>
    <!-- </b-card-body> -->

  </b-card>
</template>

<script>
import {

} from 'bootstrap-vue'
// import { avatarText } from '@core/utils/filter'
// import useUsersList from '../users-list/useUsersList'

export default {
  components: {

  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // const { resolveUserRoleVariant } = useUsersList()
    // return {
    //   avatarText,
    //   resolveUserRoleVariant,
    // }
  },

  methods: {
    formatDate(oldDate) {
      const date = new Date(oldDate)
      const year = date.getFullYear()
      // let month = date.getMonth()+1;
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()

      return `${day} ${month} ${year}`
      // return new Date(oldDate).toDateString();
      // return new Date(oldDate).toISOString();
    },
  },
}
</script>

<style>

</style>
